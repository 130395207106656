/* Utils */

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif;
}

.group:after {
  content: '';
  display: table;
  clear: both;
}

/* No Match */

.noMatch {
  background: #0000;
}

.noMatch p {
  margin: 0;
  padding: 0 0 16px 0;
}

.noMatchContainer {
  max-width: 488px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 2px;
  margin: 64px 28px 0 28px;
  padding: 0;
}

.noMatchContainerHeading {
  padding: 16px 16px 0 14px;
  line-height: 24px;
  font-size: 13px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
}

.noMatchContainerHeading svg {
  fill: #4285f4;
  vertical-align: top;
  padding: 0;
  margin: 0 12px 0 0;
  width: 24px;
  height: 24px;
}

.noMatchContainerMessage {
  padding: 16px;
  font-size: 15px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
}

@media screen and (min-width: 544px) {
  .noMatchContainer {
    margin: 100px auto;
  }

  .noMatch p {
    padding: 0 0 4px 0;
  }
}

/* Main */

.reportLoading {
  width: 100px;
  text-align: center;
  margin: 234px auto 0 auto;
}

.reportLoading svg {
  width: 50px;
  height: 100%;
  fill: #919191;
}

.reportBox {
  max-width: 900px;
  margin: 41px 16px 82px 16px;
  padding: 0;
}

.reportHeaderContainer {
  background: #ffffff;
  border-bottom: 1px solid #dddfe2;
}

.reportHeader {
  padding: 0 0 9px 0;
}

.reportHeaderFont {
  margin: 0;
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: normal;
  font-weight: bold;
  word-wrap: break-word;
  text-align: center;
  color: #424242;
}

.reportHeaderDate {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  color: #5f6368;
  margin: 50px 0 50px 0;
}

.reportHeaderCompanyName {
  text-align: center;
}

.reportHeaderLogo {
  display: block;
  margin: 0 auto 0 auto;
  width: 250px;
  height: 150px;
  border: 1px solid #dddfe2;
  border-radius: 4px;
  padding: 24px;
}

.reportNavBar {
  height: 55px;
  background: #4e5665;
  color: #ffffff;
}

.reportNavBar a {
  text-decoration: none;
  color: #ffffff;
}

.reportNavBar ul {
  list-style: none;
  text-align: center;
  font-size: 14px;
  padding: 0;
  margin: 0;
}

.reportNavBar ul li {
  width: 25%;
  float: left;
  line-height: 52px;
}

.reportNavBar ul li.active {
  border-bottom: 3px solid #ffffff;
}

.reportContentContainer {
  padding: 12px 0px 32px 0px;
}

.reportSectionFont {
  color: #424242;
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  margin: 0;
}

.reportContentBoxSmall,
.reportContentBoxSmallMiddle {
  float: none;
  width: 288px;
  border: 1px solid #dddfe2;
  border-radius: 4px;
  margin: 16px auto;
}

.reportContentBoxLarge {
  width: 100%;
  margin: 16px 0 0 0;
  padding: 0;
  border: 1px solid #dddfe2;
  border-radius: 4px;
}

.reportContentBoxMediumLeft,
.reportContentBoxMediumRight {
  float: none;
  max-width: 442px;
  margin: 16px auto;
  padding: 0;
  border: 1px solid #dddfe2;
  border-radius: 4px;
}

.reportContentBoxHeading {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif;
  color: #383838;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  border-bottom: 1px solid #dddfe2;
  text-align: left;
  padding: 12px 24px 12px 24px;
  margin: 0;
  background: #fafafa;
}

.reportContentBoxMain {
  padding: 48px 24px 48px 24px;
  text-align: center;
}

.reportContentHeadingFont,
.reportContentDemographicHeading {
  color: #4e5665;
  font-family: Helvetica, Arial, sans-serif !important;
  font-size: 18px;
  font-weight: 100;
  padding: 8px 0;
  text-align: center;
  margin: 0;
}

.reportContentOverviewBox {
  background: #ffffff;
  border-top: 1px solid #dddfe2;
  border-bottom: 1px solid #dddfe2;
  border-radius: 3px;
  margin-bottom: 12px;
  padding: 20px 16px 20px 16px;
}

.reportContentOverviewText {
  color: #4e5665;
}

.reportContentHighlightBox {
  width: 100%;
  padding: 0;
  margin-bottom: 12px;
}

.reportContentHighlightInsideBox {
  background: #ffffff;
  border: 1px solid #dddfe2;
  border-radius: 3px;
  margin: 0 auto;
  padding: 12px 30px 12px 30px;
  text-align: center;
}

.reportContentHighlightHeading {
  color: #4e5665;
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif;
  font-size: 14px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.reportContentHighlightNumber {
  color: #323232;
  font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 42px;
  font-weight: 700;
  line-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.reportContentHighlightComparison {
  color: #929598;
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif;
  font-size: 12px;
  font-weight: lighter;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.reportContentHighlightPositive {
  color: #42b72a;
  font-weight: bold;
}

.reportContentHighlightNegative {
  color: #fa3e3e;
  font-weight: bold;
}

.reportContentHighlightChart {
  margin: 32px 0 0 0;
}

.reportContentClickMetricBox {
  background: #ffffff;
  padding: 12px 24px 12px 24px;
  margin: 0;
}

.reportContentClickMetricStatsBox {
  margin: 0;
  padding: 0 24px 12px 0;
  display: inline-block;
}

.desktopColor {
  color: #0176ff;
}

.tabletColor {
  color: #01d5aa;
}

.mobileColor {
  color: #550cd5;
}

.reportContentTopLocationBox {
  background: #ffffff;
  padding: 0 6px 0 0;
  margin: 0;
}

.reportContentDemographicInsideBox {
  background: #ffffff;
  margin: 0 auto;
  padding: 12px 6px 12px 0;
  text-align: center;
}

.reportContentGenderLeft {
  float: left;
  width: 30%;
  text-align: left;
  padding: 20px 0 0 20px;
}

.reportContentGenderRight {
  float: left;
  width: 70%;
}

.reportContentGenderCircleBox {
  margin: 0;
  width: 180px;
  padding: 20px 10px 20px 10px;
}

.reportContentGenderTextBox {
  padding: 8px 0 0 0;
}

.reportContentGenderPercentText {
  font-size: 20px;
  font-weight: 700;
  line-height: 14px;
  color: #4b4f56;
  margin: 0;
}

.reportContentGenderMaleText {
  font-size: 14px;
  font-weight: 700;
  color: #80aaff;
  text-transform: capitalize;
  margin: 0;
  line-height: 26px;
}

.reportContentGenderFemaleText {
  font-size: 14px;
  font-weight: 700;
  color: #9e43df;
  text-transform: capitalize;
  margin: 0;
  line-height: 26px;
}

.reportContentQueryBox {
  float: left;
  width: 75%;
}

.reportContentQueryClicks {
  float: left;
  width: 25%;
  text-align: right;
}

.reportContentQueryHeading {
  font-size: 14px;
  line-height: 22px;
  padding: 12px 0 12px 0;
  display: block;
  width: 100%;
  font-weight: bold;
  text-shadow: 0 1px 0 #fff;
}

.reportContentQueryList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.reportContentQueryList li {
  font-size: 16px;
  text-decoration: none;
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif;
  font-weight: 400;
  line-height: 24px;
  padding: 6px 0 6px 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.reportContentResponsiveBox {
  background: #ffffff;
  margin: 0;
  padding: 12px 24px 12px 24px;
}

.reportContentPaginationContainer {
  text-align: right;
  width: 100%;
  padding: 6px 12px 6px 0;
  margin: 0;
}

.reportArrowInActive {
  fill: #bdbdbd;
}

.reportArrowActive {
  fill: #4e5665;
}

.reportFeedbackText {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}
.reportFeedbackSmileyBox {
  text-align: center;
  margin: 41px 0 0 0;
  padding: 0;
}

.reportFeedbackSmiley {
  padding: 4px;
  width: 52px;
  height: 52px;
  fill: #424242;
}

.reportFeedbackSmileyBad {
  padding: 4px;
  width: 52px;
  height: 52px;
  fill: #e41b1d;
}

.reportFeedbackSmileyUnhappy {
  padding: 4px;
  width: 52px;
  height: 52px;
  fill: #e5636a;
}

.reportFeedbackSmileyNeutral {
  padding: 4px;
  width: 52px;
  height: 52px;
  fill: #c89719;
}

.reportFeedbackSmileyHappy {
  padding: 4px;
  width: 52px;
  height: 52px;
  fill: #80bb5f;
}

.reportFeedbackSmileyGood {
  padding: 4px;
  width: 52px;
  height: 52px;
  fill: #108827;
}

.reportSection {
  border-top: 4px solid #4285f4;
  text-align: left;
  padding: 41px 0;
  margin: 41px 0 0 0;
}

.reportContentListBox {
  margin: 12px 24px 0px 24px;
  padding: 0;
  border-bottom: 1px solid #dddfe2;
}

.reportContentPaginationArrow {
  float: right;
  margin: 0;
  height: 36px;
  padding: 6px;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

.reportContentPaginationArrow:hover {
  background: #f2f2f2;
  border-radius: 50%;
}

.reportContentPaginationNumber {
  margin: 0;
  padding: 0 6px 0 0;
  line-height: 36px;
  color: #929598;
  font-size: 14px;
  float: right;
}

@media screen and (min-width: 768px) {
  .reportContentBoxSmall,
  .reportContentBoxSmallMiddle {
    float: left;
    width: 288px;
    border: 1px solid #dddfe2;
    border-radius: 4px;
    margin: 16px 16px 0 0;
  }

  .reportContentHighlightBox {
    width: 50%;
    float: left;
    padding: 0 8px 0 8px;
  }

  .reportContentResponsiveBox,
  .reportContentOverviewBox {
    margin: 0 8px 12px 8px;
  }
}

@media screen and (min-width: 900px) {
  .reportBox {
    margin: 41px auto 82px auto;
  }

  .reportContentBoxSmall {
    float: left;
    width: 288px;
    border: 1px solid #dddfe2;
    border-radius: 4px;
    margin: 16px 0 0 0;
  }

  .reportContentBoxSmallMiddle {
    float: left;
    width: 288px;
    border: 1px solid #dddfe2;
    border-radius: 4px;
    margin: 16px 18px 0 18px;
  }

  .reportContentBoxMediumLeft {
    float: left;
    width: 442px;
    margin: 16px 0 0 0;
    padding: 0;
    border: 1px solid #dddfe2;
    border-radius: 4px;
  }

  .reportContentBoxMediumRight {
    float: left;
    width: 442px;
    margin: 16px 0 0 16px;
    padding: 0;
    border: 1px solid #dddfe2;
    border-radius: 4px;
  }

  .reportContentContainer {
    padding: 0px 8px 32px 8px;
  }

  .reportContentOverviewBox {
    background: #ffffff;
    border: 1px solid #dddfe2;
    border-radius: 3px;
    margin-bottom: 12px;
    padding: 20px 30px 20px 30px;
  }

  .reportContentHeadingFont {
    color: #4e5665;
    font-family: Helvetica, Arial, sans-serif !important;
    font-size: 18px;
    font-weight: 100;
    padding: 22px 0 11px 8px;
    text-align: left;
    margin: 0;
  }
  .reportContentDemographicHeading {
    color: #4e5665;
    font-family: Helvetica, Arial, sans-serif !important;
    font-size: 18px;
    font-weight: 100;
    padding: 22px 0 11px 0;
    text-align: left;
    margin: 0;
  }
}

.reportTable {
  margin: 12px 24px 0px 24px;
  padding: 0 0 12px 0;
  border-bottom: 1px solid #dddfe2;
}

.reportTableRow {
  margin: 12px 0 0 0;
  font-size: 16px;
}

.reportTableRowHead {
  margin: 24px 0 0 0;
  padding: 0 0 12px;
  font-size: 14px;
  font-weight: bold;
}

.reportTableRowLeft {
  float: left;
  text-align: left;
  width: 120px;
}

.reportTableRowRight {
  float: right;
  width: 100px;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reportTableFetching {
  width: 100px;
  text-align: center;
  margin: 0 auto 0 auto;
}

.reportTableFetching svg {
  width: 50px;
  height: 100%;
  fill: #919191;
}
